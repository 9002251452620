// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"

// Register each controller with Stimulus
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)

import NestedForm from 'stimulus-rails-nested-form'
application.register('nested-form', NestedForm)

import CheckboxSelectAll from '@stimulus-components/checkbox-select-all'
application.register('checkbox-select-all', CheckboxSelectAll)

import SignaturePad from 'signature_pad'
application.register('signature-pad', SignaturePad)

import "mapkick/bundle"
application.register('mapkick/bundle', Mapkick)

import ahoy from "ahoy.js"

import HwComboboxController from "@josefarias/hotwire_combobox"
application.register("hw-combobox", HwComboboxController)

// import turf from '@turf/turf'

import { Controller } from "@hotwired/stimulus"
import SignaturePad from 'signature_pad'

export default class extends Controller {
  static targets = [ "signature", "signatureImage", "clear", "submit", "info"]

  connect() {
    this.signaturePad = new SignaturePad(this.signatureTarget)
    let submitButton = document.getElementById("client-waiver-submit")
    this.signatureTarget.width = this.element.offsetWidth
    this.signaturePad.clear()
    submitButton.disabled = true

    this.signaturePad.addEventListener("endStroke", () => {
      this.signatureImageTarget.value = this.signaturePad.toDataURL()
      this.checkSignature()
    })

    this.clearTarget.addEventListener("click", () => {
      this.signaturePad.clear()
      this.signatureImageTarget.value = null
      this.checkSignature()
    })
  }

  checkSignature() {
    let submitButton = document.getElementById("client-waiver-submit")
    if (this.signatureImageTarget.value) {
      submitButton.disabled = false
    } else {
      submitButton.disabled = true
    }
  }

  resizeCanvas() {
    // this.signatureTarget.width = 500
    // this.signatureTarget.height = 200
    // debugger
    const canvas = this.signatureTarget;
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    signaturePad.clear();
  }
}
